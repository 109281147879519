@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard-dynamic-subset.css");
@layer base {
    html {
        font-family: pretendard, system-ui, sans-serif;
        @apply select-none;
    }
}
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }@keyframes fadeOut {
        from { opacity: 1; }
        to { opacity: 0; }
    }
    /* input */
    .inner_input {
        @apply font-medium appearance-none w-full h-full outline-none pr-3 py-2 focus:outline-none focus:ring-blue-500 focus:ring-inset focus:ring-[1.5px] transition-all
    }
    .inner_input_res {
        @apply font-medium appearance-none w-full h-full outline-none pr-3 py-2 flex items-center
    }
    .inner_input_error{
        @apply font-medium appearance-none w-full h-full outline-none pr-3 py-2 focus:outline-none focus:ring-rose-500 focus:ring-inset focus:ring-[1.5px] transition-all
    }
    .inner_select {
        @apply appearance-none w-full h-full outline-none pl-2 pr-3 py-2
    }
    .inner_select_error{
        @apply appearance-none w-full h-full outline-none pl-2 pr-3 py-2
    }



    /* error */
    .error {
        @apply absolute inset-y-0 right-2 flex items-center text-rose-500 font-medium bg-white m-1 text-xs lg:text-base
    }

    /* admin */
    .adminBox {
        @apply flex rounded-xl px-4 md:px-12 py-3 md:py-5 md:my-0 my-5 shadow md:space-y-1 font-medium bg-white
    }
    .adminBox_clickable {
        @apply rounded-xl px-4 md:px-12 py-3 md:py-5 md:my-0 my-5 md:grid grid-cols-2 shadow md:space-y-1 font-medium bg-white cursor-pointer
    }

    .adminBox_title{
        @apply text-lg font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center
    }

    /*날씨박스*/
    .weather_title {
        @apply text-gray-500 font-bold
    }

    /*풀캘린더*/
    .fc-toolbar-title{
        @apply font-pretendard font-semibold !text-base lg:!text-xl
    }
    .fc-button {
        @apply font-pretendard
    }
    .fc-button-primary {
        @apply !bg-transparent !border-none !text-gray-400 !rounded-full md:!mx-1 !shadow-none md:hover:!shadow !text-xs md:!text-base
    }

    .fc-dayGridMonth-view {
        @apply !border-none *:!border-none lg:!text-base !text-xs
    }
    .fc-theme-standard td {
        @apply !border-none *:!border-none
    }
    .fc-theme-standard th {
         @apply !border-none *:!border-none
    }
    /* 일 표시 */
    .fc-daygrid-day-top{
        @apply !justify-center
    }
    /* 일요일 날짜 빨간색 */
    .fc-day-sun a {
        @apply !text-red-500
    }

    /* 토요일 날짜 파란색 */
    .fc-day-sat a {
        @apply !text-blue-500
    }

     /*월별 일정*/
    .fc-daygrid-day-events{
        @apply flex justify-center items-center flex-wrap
    }
    .fc-daygrid-event-harness{
        @apply flex justify-center
    }
    .fc-daygrid-event{
        @apply !w-fit !h-fit
    }

    .fc-daygrid-dot-event{
        @apply rounded-full p-0
    }

    /*오늘 날짜*/
    .fc-day-today{
        @apply !bg-blue-500/15
    }

    /*주별 일정*/
    .fc-timegrid-slot-label{
        @apply !text-xs lg:!text-base
    }
    .fc-day{
        @apply !text-xs lg:!text-base
    }


    /* 이전 달, 다음 달, 오늘 */
    /*.fc-header-toolbar .fc-toolbar-chunk:first-of-type{*/
    /*    @apply !hidden lg:!flex*/
    /*}*/

    /*페이지네이션*/
    .pageNumBtn{
        @apply bg-inherit text-neutral-800 cursor-pointer py-1 px-3 rounded-full hover:bg-white transition-colors mr-[1px]
    }